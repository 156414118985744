import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useRouter } from "next/router";
import cn from "classnames";
import Link from "next/link";
import Dropdown from "./Dropdown";
import styles from "./styles.module.scss";
import { track } from "../../utils/amplitude";
import Sibebar from "./Sibebar";
import { RoutesPaths } from "../../shared/paths.config";

export const links = [
  {
    title: "Product",
    link: "/product",
    type: "product",
    sub: [
      {
        id: "1",
        title: "Apphud for development",
        description: "Easily integrate in-app subscriptions",
        img: "build",
        link: "/development",
      },

      {
        id: "2",
        title: "Apphud for marketing",
        description: "Analyze app revenue metrics",
        img: "measure",
        link: "/marketing",
      },

      {
        id: "3",
        title: "Apphud for product",
        description:
          "Win back lapsed customers, reduce churn, run A/B experiments",
        img: "grow",
        link: "/product",
      },

      {
        id: "4",
        title: "Flows (new!)",
        description:
          "Built web-to-web campaigns that convert - for free and without code",
        img: "flows",
        link: "/flows",
      },

      {
        id: "5",
        title: "Integrations",
        description: "Send subscription events to third-party tools",
        img: "infrastructure",
        link: "/integrations",
      },
    ],
  },
  {
    title: "Why Apphud?",
    link: "/why-apphud",
  },
  {
    title: "Pricing",
    link: "/pricing",
  },
  {
    title: "Resources",
    type: "resources",
    sub: [
      {
        id: "1",
        title: "Quickstart guide",
        img: "quickstart",
        link: "https://docs.apphud.com/docs/quickstart",
        onClick() {
          track("header_menu_external_link_clicked", {
            target_url: "https://docs.apphud.com/docs/quickstart",
          });
        },
        blank: true,
      },

      {
        id: "6",
        title: "Blog",
        img: "blog",
        link: "/blog",
        onClick() {
          track("header_menu_external_link_clicked", {
            target_url: "/blog",
          });
        },
        blank: true,
      },

      {
        id: "4",
        title: "Documentation",
        img: "doc",
        link: "https://docs.apphud.com/",
        blank: true,
        onClick() {
          track("header_menu_external_link_clicked", {
            target_url: "https://docs.apphud.com/",
          });
        },
      },

      {
        id: "7",
        title: "Case study",
        img: "case-study",
        link: "https://apphud.com/blog/categories/case_study",
        onClick() {
          track("header_menu_external_link_clicked", {
            target_url: "https://apphud.com/blog/categories/case_study",
          });
        },
        blank: true,
      },

      {
        id: "2",
        title: "Installation",
        link: "https://docs.apphud.com/docs/sdk-integration",
        onClick() {
          track("header_menu_external_link_clicked", {
            target_url: "https://docs.apphud.com/docs/sdk-integration",
          });
        },
        description: (
          <>
            <a
              href="https://docs.apphud.com/docs/ios"
              target="_blank"
              onClick={() => {
                track("header_menu_external_link_clicked", {
                  target_url: "https://docs.apphud.com/docs/ios",
                });
              }}
            >
              iOS
            </a>{" "}
            <a
              href="https://docs.apphud.com/docs/android"
              target="_blank"
              onClick={() => {
                track("header_menu_external_link_clicked", {
                  target_url: "https://docs.apphud.com/docs/android",
                });
              }}
            >
              Android
            </a>
          </>
        ),
        img: "installation",
        blank: true,
      },

      {
        id: "Free services",
        title: "Free services",
        link: "",
        onClick() {
          track("header_menu_external_link_clicked", {
            target_url: "https://docs.apphud.com/docs/sdk-integration",
          });
        },
        description: (
          <>
            <a
              href="https://apphud.com/apple-receipt-checker"
              target="_blank"
              onClick={() => {
                track("header_menu_external_link_clicked", {
                  target_url: "https://apphud.com/apple-receipt-checker",
                });
              }}
            >
              Apple Receipt Checker
            </a>
            <a
              href="https://apphud.com/apple-fiscal-calendar-2024"
              target="_blank"
              onClick={() => {
                track("header_menu_external_link_clicked", {
                  target_url: "https://apphud.com/apple-fiscal-calendar-2024",
                });
              }}
            >
              Apple’s Fiscal Calendar
            </a>
            <a
              href="https://apphud.com/gallery"
              target="_blank"
              onClick={() => {
                track("header_menu_external_link_clicked", {
                  target_url: "https://apphud.com/gallery",
                });
              }}
            >
              Paywalls Gallery
            </a>
            <a
              href="https://apphud.com/subscription-app-revenue-calculator"
              target="_blank"
              onClick={() => {
                track("header_menu_external_link_clicked", {
                  target_url:
                    "https://apphud.com/subscription-app-revenue-calculator",
                });
              }}
            >
              App Revenue Calculator
            </a>
          </>
        ),
        img: "grow",
        blank: true,
      },
      {
        id: "3",
        title: "System status",
        img: "system-status",
        link: "https://status.apphud.com",
        blank: true,
        onClick() {
          track("header_menu_external_link_clicked", {
            target_url: "https://status.apphud.com",
          });
        },
      },
    ],
  },
  {
    title: "Contact",
    link: "/contact",
  },
  {
    title: "Sign in",
    link: "https://app.apphud.com/",
  },
  {
    title: "Book a demo",
    link: "/demo",
  },
];

const Header = () => {
  const router = useRouter();
  const [scrollTop, setScrollTop] = useState(0);
  const [open, setOpen] = useState(false);

  useLayoutEffect(() => {
    setOpen(false);
    document.body.style.overflow = "";
  }, [router]);

  const onToggle = useCallback(() => {
    const newOpen = !open;

    if (newOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    setOpen(newOpen);
  }, [open]);
  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (router.pathname === RoutesPaths.FLOW) {
    return null;
  }

  return (
    <div className={`${styles.header} ${scrollTop > 69 && styles.bordered}`}>
      {open && <Sibebar onClose={onToggle} />}
      <div className={cn(styles.container, "container")}>
        <Link href="/">
          <img
            width="120"
            height="24"
            src="/images/logo-header.svg"
            alt="Apphud"
            className={styles.logo}
          />
        </Link>
        <div className={styles.burger} onClick={onToggle} />
        <div className={styles.nav}>
          {[
            [0, links.length - 2],
            [links.length - 2, links.length],
          ].map((range, index) => (
            <div className={styles.group} key={index}>
              {[...links].splice(...range).map((link, i) => (
                <>
                  {link?.sub ? (
                    <Dropdown items={link.sub} type={link.type} large>
                      <>
                        {link?.link ? (
                          <Link href={link?.link}>{link.title}</Link>
                        ) : (
                          <a>{link.title}</a>
                        )}
                        <span>{link.title}</span>
                      </>
                    </Dropdown>
                  ) : link?.link ? (
                    <Link href={link.link}>{link.title}</Link>
                  ) : (
                    <>{link.title}</>
                  )}
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
